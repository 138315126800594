import React from "react"

import Layout from "../components/layout"

const IndexPage = () => (
  <>
    <form name={"down-funnel"} data-netlify={"true"} netlify-honeypot={"bot-field"} hidden>
      <input name={"form-name"} type={"hidden"} value={"down-funnel"} />
      <input name={"bot-field"} />
      <input name={"eventHash"} />
      <input name={"classSelections"} />
      <input name={"email"} type={"text"} />
    </form>
    <Layout/>
  </>
)

export default IndexPage
